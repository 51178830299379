//// JS MAIN FILE ////

// Import Webflow
import { webflow } from './modules/_webflow.js';
webflow();

// Import modules
import { gallery } from './modules/_gallery.js';
gallery();

// Import templates
